<template>
    <div class="get-coupon" v-if="data.title">
        <div class="coupon-info">
            <div class=" flex">
                <div class="info-item-label">活动时间：</div>
                <div>{{ $$(data.start_time).slice(0, 10) }} 至 {{ $$(data.end_time).slice(0, 10) }}</div>
            </div>
            <div class="flex">
                <div class="info-item-label">活动规则：</div>
                <div>下单立减<strong>¥{{ data.sale_off }}</strong></div>
            </div>
            <div class="flex">
                <div class="info-item-label">优惠券数量：</div>
                <div>{{ data.total }}份</div>
            </div>
            <div class="flex">
                <div class="info-item-label">优惠券剩余：</div>
                <div>{{ data.limit }}份</div>
            </div>

        </div>
        <div class="coupon-box">
            <CouponsCell :data="data"/>
        </div>
        <div class="btn" @click="handReceive">
            立即领取
        </div>
    </div>
    <van-empty description="暂无数据" v-else/>
</template>

<script>
    import CouponsCell from "../../components/CouponsCell";
    import {Toast} from "vant";

    export default {
        name: "",
        components: {
            CouponsCell
        },
        data() {
            return {
                data: {}
            }
        },
        created() {
            let sn = this.$route.query.coupon_sn
            this.$request.get("get_coupon_info", {
                coupon_sn: sn
            }).then(res => {
                console.log(res)
                if (res.code == 1) {
                    this.data = res.data
                    if(res.data.status == 0 || res.data.status == 3 || res.data.status == 4 || res.data.status == 2 ){
                        this.$router.push({
                            path: '/my/use_coupons',
                            query: {
                                coupon_sn: this.data.sn
                            }
                        })
                    }
                } else {
                    Toast(res.message)
                }
            })
        },
        methods: {
            handReceive() {
                if (this.data.status === 2) {
                    this.$router.push({
                        path: '/my/use_coupons',
                        query: {
                            coupon_sn: this.data.sn
                        }
                    })
                } else {
                    this.$request.get("recive_coupon", {
                        coupon_sn: this.data.sn
                    }).then(res => {
                        if (res.code == 1) {
                            this.$router.push({
                                path: '/my/use_coupons',
                                query: {
                                    coupon_sn: this.data.sn
                                }
                            })
                        } else {
                            Toast(res.message)
                        }

                    })
                }

            }
        }

    }
</script>

<style scoped lang="less">

    .get-coupon {
        width: 100%;
        min-height: 100vh;
        background-color: #F3F3F4;

        .coupon-info {
            padding: 20px;
            box-sizing: border-box;
            font-size: 14px;
            color: #666666;
            line-height: 25px;
            margin-bottom:0px;
            .info-item-label  + div{
                //letter-spacing: 1px;
            }
            strong {
                font-weight:700;
                font-size: 15px;
                color: #FF9617;
                margin-left: 5px;
            }
        }

        .coupon-box {
            padding: 0px 10px;
            box-sizing: border-box;
        }

        .btn {
            width: 80%;
            background-color: white;
            font-size: 16px;
            font-weight: 700;
            color: #6B00AF;
            line-height: 28px;
            text-align: center;
            margin: 0 auto;
            margin-top: 55px;
            padding: 8px 0px 7px 0px;
            border-radius: 20px;
        }
    }

</style>